

















import * as vr from '@/helpers/validation';
import { getRolesArray } from '@/helpers/tokenHelpers';
import { ERole } from '@/plugins/role-permissions/types';
import type { PropType } from 'vue';
import { mapGetters, mapState } from 'vuex';

export default {
  name: 'SettingsRolesSelect',
  props: {
    value: {
      type: String as PropType<string>,
      default: (): string => null
    },
    hideDetails: {
      type: Boolean as PropType<boolean>,
      default: (): boolean => false
    },
    disabled: {
      type: Boolean,
      default: (): boolean => false
    },
    accountsRoles: {
      type: Boolean,
      default: (): boolean => false
    }
  },
  data(): any {
    return {
      accountsRolesList: [
        {
          label: 'Account',
          value: ERole.ROLE_ACCOUNT
        },
        {
          label: 'CopyStake Account',
          value: ERole.ROLE_COPY_STAKE_ACCOUNT
        }
      ],
      copyStakeRolesList: [
        {
          label: 'Admin',
          value: ERole.ROLE_OPERATOR_COPY_STAKE_ADMIN
        },
        {
          label: 'Support',
          value: ERole.ROLE_OPERATOR_COPY_STAKE_SUPPORT
        }
      ]
    };
  },
  computed: {
    ...mapState('app', ['isSuperAdmin']),
    ...mapGetters('app', ['isCopyStakeRole']),
    ...mapGetters('SuperAdmin', ['isCurrentCopyStakeAccount']),
    rules(): any {
      return [vr.required];
    },
    rolesList(): { value: string; label: string }[] {
      // (DEV-3562 Change naming for roles) Delete when the keys change on the backend
      const customLabels = [
        {
          label: 'Pitboss',
          value: ERole.ROLE_OPERATOR_SUPPORT
        },
        {
          label: 'Support',
          value: ERole.ROLE_OPERATOR_GUEST
        }
      ];

      return [
        ...getRolesArray([
          ERole.ROLE_OPERATOR_ADMIN,
          ERole.ROLE_OPERATOR_MARKETER,
          ERole.ROLE_OPERATOR_FINANCE,
          ERole.ROLE_OPERATOR_DEVELOPER,
          ERole.ROLE_OPERATOR_VIEWER
        ]),
        ...customLabels
      ];
    },
    roles(): { value: string; label: string }[] {
      if (this.accountsRoles) {
        return this.accountsRolesList;
      }

      if (this.isCopyStakeRole) {
        return this.copyStakeRolesList;
      }

      if (this.isCurrentCopyStakeAccount) {
        return this.copyStakeRolesList.map(
          (item: { value: string; label: string }) => ({
            ...item,
            label: 'CopyStake ' + item.label
          })
        );
      }

      return this.rolesList;
    }
  }
};
