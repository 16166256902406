














































import { mapActions, mapGetters, mapState } from 'vuex';
import FeatureCard from '@/components/feature-flags/FeatureFlagCard.vue';
import FeatureFlagManageDialog from '@/components/feature-flags/FeatureFlagManageDialog.vue';
import { getCopyStakeFeatureFlagItems } from '@/api/CopyStake/FeatureFlags';
import { errorToastMessage } from '@/helpers/errorToastMessage';
import { ICopyStakeFeatureFlagItem } from '@/api/schema';
import type { AxiosError } from 'axios';

export default {
  name: 'FeatureFlagsCopyStakeView',
  components: {
    FeatureCard,
    FeatureFlagManageDialog
  },
  data(): any {
    return {
      featureManageModalState: false,
      manageModalFeature: {} as ICopyStakeFeatureFlagItem,

      features: [] as ICopyStakeFeatureFlagItem[],
      loading: {
        featuresList: false,
        updateManageModal: false
      }
    };
  },
  computed: {
    ...mapGetters('Onboarding', ['operatorId']),
    ...mapState('app', ['isSuperAdmin']),
    sortedFeatureList(): ICopyStakeFeatureFlagItem[] {
      const sortedFeatures = [...this.features].sort((a, b) =>
        a.name.localeCompare(b.name)
      );

      return this.isSuperAdmin
        ? sortedFeatures
        : sortedFeatures.filter((feature: ICopyStakeFeatureFlagItem) => {
            return feature.adminVisible;
          });
    }
  },
  watch: {
    operatorId: {
      handler(newId: number | null): void {
        if (!newId) return;

        this.getFeatureFlagsData();
      },
      immediate: true
    }
  },
  methods: {
    ...mapActions('FeatureFlags', ['getOperatorCopyStakeFeatureFlags']),
    async getFeatureFlagsData(): Promise<void> {
      this.loading.featuresList = true;
      this.features = [];

      await getCopyStakeFeatureFlagItems()
        .then((res: ICopyStakeFeatureFlagItem[]): void => {
          this.features = res || [];
        })
        .catch((error: AxiosError): void => {
          this.features = [];
          errorToastMessage(error);
        })
        .finally((): void => {
          this.loading.featuresList = false;
        });
    },
    featureManageModalStateHandler(feature: ICopyStakeFeatureFlagItem): void {
      if (feature) {
        this.manageModalFeature = feature;
        this.featureManageModalState = true;
      }
    },
    submitManageModalHandler(): void {
      this.loading.updateManageModal = true;

      this.getFeatureFlagsData().then((): void => {
        this.manageModalFeature = this.features.find(
          (feature: ICopyStakeFeatureFlagItem) =>
            feature.id === this.manageModalFeature.id
        );

        this.loading.updateManageModal = false;
      });
    }
  }
};
