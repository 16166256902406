





















































import { mapActions, mapGetters, mapState } from 'vuex';
import FeatureCard from '@/components/feature-flags/FeatureFlagCard.vue';
import FeatureFlagManageDialog from '@/components/feature-flags/FeatureFlagManageDialog.vue';
import {
  adminGetOperatorLoyaltyFeatureFlags,
  getOperatorLoyaltyFeatureFlags
} from '@/api/FeatureFlags';
import { errorToastMessage } from '@/helpers/errorToastMessage';
import { ILoyaltyFeatureFlagItem, EFeatureFlagType } from '@/api/schema';
import type { AxiosError } from 'axios';
import { ELoyaltyFeatureFlag } from '@/store/modules/feature-flags/FeatureFlagNames';

export default {
  name: 'FeatureFlagsView',
  components: {
    FeatureCard,
    FeatureFlagManageDialog
  },
  data(): any {
    return {
      featureManageModalState: false,
      manageModalFeature: {} as ILoyaltyFeatureFlagItem,

      features: [] as ILoyaltyFeatureFlagItem[],
      loading: {
        featuresList: false,
        updateManageModal: false
      },

      selectedCategoriesType: EFeatureFlagType.PRODUCT,
      selectCategoriesTypes: [
        {
          text: 'Product Features',
          value: EFeatureFlagType.PRODUCT
        },
        {
          text: 'Tech Features',
          value: EFeatureFlagType.TECH
        }
      ]
    };
  },
  computed: {
    ...mapGetters('Onboarding', ['operatorId']),
    ...mapState('app', ['isSuperAdmin']),
    sortedFeatureList(): ILoyaltyFeatureFlagItem[] {
      const sortedFeatures = [...this.features].sort((a, b) =>
        (a.name === ELoyaltyFeatureFlag.COPY_STAKE
          ? 'copystake'
          : a.name
        ).localeCompare(
          b.name === ELoyaltyFeatureFlag.COPY_STAKE ? 'copystake' : b.name
        )
      );

      return this.isSuperAdmin
        ? sortedFeatures
        : sortedFeatures.filter((feature: ILoyaltyFeatureFlagItem) => {
            return (
              feature.adminVisible &&
              feature.type === EFeatureFlagType.PRODUCT
            );
          });
    }
  },
  watch: {
    operatorId: {
      handler(newId: number | null): void {
        if (!newId) return;

        this.getFeatureFlagsData();
      },
      immediate: true
    }
  },
  methods: {
    ...mapActions('FeatureFlags', ['getOperatorLoyaltyFeatureFlags']),
    async getFeatureFlagsData(): Promise<void> {
      this.loading.featuresList = true;
      this.features = [];

      const request = this.isSuperAdmin
        ? adminGetOperatorLoyaltyFeatureFlags
        : getOperatorLoyaltyFeatureFlags;

      await request(this.selectedCategoriesType)
        .then((data: ILoyaltyFeatureFlagItem[]): void => {
          this.features = data;
        })
        .catch((error: AxiosError): void => {
          this.features = [];
          errorToastMessage(error);
        })
        .finally((): void => {
          this.loading.featuresList = false;
        });
    },
    handlerCategoriesTypeChange(): void {
      this.getFeatureFlagsData();
    },
    featureManageModalStateHandler(feature: ILoyaltyFeatureFlagItem): void {
      if (feature) {
        this.manageModalFeature = feature;
        this.featureManageModalState = true;
      }
    },
    submitManageModalHandler(): void {
      this.loading.updateManageModal = true;

      this.getFeatureFlagsData().then((): void => {
        this.manageModalFeature = this.features.find(
          (feature: ILoyaltyFeatureFlagItem) =>
            feature.featureFlagId === this.manageModalFeature.featureFlagId
        );

        this.loading.updateManageModal = false;
      });
    }
  }
};
